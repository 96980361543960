import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles/DemoPlayer.css'

import React, { createRef, useState } from 'react';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import ReactSlider from 'react-slider';
import ReactPlayer from 'react-player';
import Reel from '../../../../../../../resources/audio/Website_Demo_Reel.mp3'
import { secondsToTimestamp } from '../../../../../../../resources/js/Helper';

const DemoPlayer = ({ demoPlaying, setDemoPlaying, handleDemoReelClick }) => {

    
    const player = createRef()

    
    const [playedFraction, setPlayedFraction] = useState(0.0)
    const [playedSeconds, setPlayedSeconds] = useState(0)
    const [maxTime, setMaxTime] = useState(0)

    const onProgress = (progress) => {
        if (demoPlaying) {
            setPlayedFraction(progress.played)
            setPlayedSeconds(progress.playedSeconds)
        }

    }
    const onDuration = (duration) => {

        setMaxTime(duration)
    }
    const onSliderClick = (position) => {
        player.current.seekTo(position / 1000, 'fraction')
        setPlayedFraction(position / 1000)
        setPlayedSeconds((position / 1000) * maxTime)
    }




    return (
        <div className={'demo-player__container flex '}  >
            <div className={'demo-player__content flex '} >
                <div className='flex' style={{width: 100 + '%'}}>
                    <ReactPlayer
                        volume={0.2}
                        ref={player}
                        onDuration={(duration) => { onDuration(duration) }}
                        onProgress={(progress) => { onProgress(progress) }}
                        url={Reel}
                        height={0} width={0} playing={demoPlaying}
                        onEnded={() => { setDemoPlaying(false) }}
                    />
                    <div className='demo-player__playpause flex flex-center' onClick={handleDemoReelClick}>
                        <FontAwesomeIcon icon={(demoPlaying) ? faPause : faPlay} />
                    </div>
                    <div className="demo-player__track-content flex flex-column flex-center">
                        <div className="demo-player__track-info">
                            Remo Deel - KnightWaters Music
                        </div>
                        <div className='demo-player__track-slider-container flex flex-row flex-align-center'>
                            <div className='demo-player__track-slider-duration flex flex-center'>{secondsToTimestamp(playedSeconds)}</div>

                            <ReactSlider
                                className="demo-player__track-slider flex flex-center"
                                thumbClassName="demo-player__track-slider-thumb"
                                trackClassName="demo-player__track-slider-track"
                                min={0}
                                max={1000}
                                value={playedFraction * 1000}
                                onSliderClick={(position) => { onSliderClick(position) }}

                            />

                            <div className='demo-player__track-slider-duration flex flex-center' >{secondsToTimestamp(maxTime)}</div>
                        </div>



                    </div>
                </div>

            </div>

        </div>
    );
};

export default DemoPlayer;