

import './styles/Listen.css'

import React from 'react';
import Soundcloud from '../../../soundcloud/Soundcloud';
import SectionTitle from '../section-title/SectionTitle.js';


const Listen = ({ demoPlaying, soundcloudPlaying, setSoundcloudPlaying }) => {


    return (
        <div className='listen__container'  >

            <div className='listen__content flex flex-column' >
                <SectionTitle>Listen</SectionTitle>

                <div className='soundcloud__container' >
                    <Soundcloud demoPlaying={soundcloudPlaying} soundcloudPlaying={demoPlaying} setSoundcloudPlaying={setSoundcloudPlaying} />
                    
                </div>

            </div>
        </div>
    );
};

export default Listen;

