
import './styles/Home.css'

import React, { useState, useRef} from 'react';
import DemoPlayer from './components/demo-player/DemoPlayer'
import { CSSTransition } from 'react-transition-group';

const Home = ({ demoPlaying, setDemoPlaying }) => {

    const [showDemoPlayer, setShowDemoPlayer] = useState(false)
    const [showContent, setShowContent] = useState(false)
    const nodeRef = useRef(null);
    const contentRef = useRef(null)
    

    const handleDemoReelClick = () => {

        if (showDemoPlayer) {
            setDemoPlaying(false)
            setShowDemoPlayer(false)
        } else if (showDemoPlayer === demoPlaying) {
            setShowDemoPlayer(true)
            setDemoPlaying(true)
        } else {
            console.log("ERROR N1337: Definitely not leet")
        }
    }
    const handleDemoPlayPauseClick = () => {
        setDemoPlaying(!demoPlaying)
    }

    

    





return (
    <div className='home__container '>
        <div className="home__content flex flex-center">
            <div className='home__hero-header flex flex-center' >
                <div className='home__hero-header__image'>
                    <img alt="home-header" src={"https://gp1.wac.edgecastcdn.net/802892/http_public_production/musicians/images/438093/original/resize:248x186/crop:x0y197w633h474/hash:1467379145/1422238067_448108471996912_8470307689470473717_n.jpg?1467379145"} style={{ maskImage: 'linear-gradient(rgb(var(--main-background-nums),1) 0%, rgb(var(--main-background-nums),1) 50%)' }} />
                </div>

                <div className="home__hero-header__text flex flex-center flex-column">
                    <div className="home__hero-header__text__title flex flex-center">
                        <strong>Josh Aguas </strong>
                    </div>

                    <div className="home__hero-header__text__subtitle flex flex-column flex-center">
                        <span>GTA based Composer for </span><span>Film, Television and Media</span>
                    </div>

                    <button onClick={handleDemoReelClick} style={{ gap: 10 }} className="home__hero-header__text__button flex flex-center " >
                        <span style={{ fontFamily: 'MontSerrat' }}>{(showDemoPlayer) ? 'STOP REEL' : 'PLAY REEL'}</span>
                    </button>

                    <div className='flex flex-center' style={{ position: 'relative', perspective: 1000 }}>
                        <CSSTransition
                            in={showDemoPlayer}
                            nodeRef={nodeRef}
                            timeout={300}
                            classNames={"demo-player"}
                            onEnter={() => setShowContent(true)}
                            onExit={() => setShowContent(false)}

                        >
                            <div className="home__hero-header__text__demo-player-container" ref={nodeRef}>

                            </div>
                        </CSSTransition>

                        <CSSTransition
                            in={showContent}
                            nodeRef={contentRef}
                            timeout={600}
                            classNames={"fly-in"}


                        >
                            <div className="home__hero-header__text__demo-player-content" ref={contentRef}>

                                {true &&
                                    <DemoPlayer
                                        show={showDemoPlayer}
                                        demoPlaying={demoPlaying}

                                        setDemoPlaying={setDemoPlaying}
                                        handleDemoReelClick={handleDemoPlayPauseClick} />
                                }
                            </div>
                        </CSSTransition>
                    </div>
                </div>

            </div>




        </div>
    </div>
);
};

export default Home;