import useScrollPosition from '../../../../hooks/use-scroll-position/useScrollPosition';
import './styles/Navigation.css'
import React from 'react';
import Resume from '../../../../../resources/files/resume.pdf'

const Navigation = ({ showNavMenu, setShowNavMenu, navFn }) => {
    const scroll = useScrollPosition();
    
    

    const handleNavOnClick = (section) => {
        navFn(section)
    }
    const handleNavIconClick = () => {
        setShowNavMenu(!showNavMenu)
    }
    const NavItems = [
        {
            key: 1,
            text: "LISTEN"
        },
        {
            key: 2,
            text: "PROJECTS"
        },
        {
            key: 3,
            text: "ABOUT"
        },
        {
            key: 4,
            text: "CONTACT"
        },
    ]
    const iconColor = (showNavMenu || scroll > 0) ? 'var(--main-background)' : 'var(--main-text)';


    return (
        <div className="navigation__container flex flex-center">


            <div className='navigation__content flex flex-center flex-row'>

                <div className='navigation__links flex flex-center flex-row'>
                    {NavItems.map((navitem) => (
                        <div className='navigation__item' key={navitem.key}>
                            <p onClick={() => handleNavOnClick(navitem.key)} style={{ color: (scroll > 0) ? 'var(--main-background)' : 'var(--main-text)' }}> {navitem.text} </p>
                            <div className="custom-underline" style={{ backgroundColor: (scroll > 0) ? 'var(--main-background)' : 'var(--main-text)' }} />
                        </div>
                    ))}
                    <div className="navigation__resume" style={{ background: (scroll > 0) ? 'var(--main-background)' : 'var(--main-text)' }}>
                        <a href={Resume}  rel="noreferrer" target='_blank' style={{
                            color: (scroll > 0) ? 'var(--main-background)' : 'var(--main-text)', 
                            borderColor: (scroll > 0) ? 'var(--main-background)' : 'var(--main-text)',
                            background: (scroll > 0) ? 'var(--main-text)' : 'var(--main-background)'
                        }} >
                            RESUME
                        </a>
                    </div>

                </div>


                <div className='nav-icon__container flex flex-center' onClick={handleNavIconClick} >
                    <div className='nav-icon__bar top' style={{ background: iconColor, transform: (showNavMenu) ? 'translateY(0px) rotateZ(45deg)' : 'translateY(' + (-7) + 'px)' }} />
                    <div className='nav-icon__bar middle' style={{ background: iconColor, transform: (showNavMenu) ? 'scaleX(0)' : 'scaleX(1)' }} />
                    <div className='nav-icon__bar bottom' style={{ background: iconColor, transform: (showNavMenu) ? 'translateY(0px) rotateZ(135deg)' : 'translateY(' + (7) + 'px)' }} />

                </div>



            </div>




        </div>
    );
};

export default Navigation;