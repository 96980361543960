import './styles/About.css'
import React from 'react';

import Socan from '../../../../../resources/images/association/SOCAN_logo.svg'
import Scgc from '../../../../../resources/images/association/scgc-logo_black.svg'
import { faBriefcase, faMusic, faSchool } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactSVG } from 'react-svg';
import SectionTitle from '../section-title/SectionTitle';
const About = () => {


    return (
        <div className="about__container">
            <div className="about__content flex flex-align-center flex-column">
                <SectionTitle>About</SectionTitle>
                <div className='about__about-me flex'>
                    <div className='about__about-me__item flex flex-align-center flex-column'>
                        <div className="about__about-me__item__icon">
                            <FontAwesomeIcon icon={faMusic} />
                        </div>
                        <h5 style={{ color: 'var(--main-text)' }}> Why build a career in music? </h5>
                        <p style={{ color: 'var(--main-text)' }}>


                            For as long as I can remember, music has been an integral part of my life. I have spent most of my life trying to understand and create music, and as I went through my formal education, I realized that becoming a composer would allow me to fully invest myself into the understanding and creation of music. This desire has driven me to dedicate myself to this craft and by doing so, I have been able to obtain a deep understanding of music.
                        </p>
                    </div>
                    <div className='about__about-me__item flex flex-align-center flex-column'>
                        <div className="about__about-me__item__icon">
                            <FontAwesomeIcon icon={faSchool} />
                        </div>
                        <h5 style={{ color: 'var(--main-text)' }}> What are your qualifications? </h5>
                        <p style={{ color: 'var(--main-text)' }}>
                            I achieved my Bachelor’s of Music at Humber College in one of Canada’s most prestigious music programs. Studying under some of Canada’s industry leading professionals, I have learned the latest in music composition and production techniques. I furthered my education at Sheridan College, earning my Certificate in Music Scoring for Screen and Stage. There, I gained a
                            comprehensive understanding of the theoretical and practical aspects of composition for all forms of media.
                        </p>
                    </div>
                    <div className='about__about-me__item flex flex-align-center flex-column'>
                        <div className="about__about-me__item__icon">
                            <FontAwesomeIcon icon={faBriefcase} />
                        </div>
                        <h5 style={{ color: 'var(--main-text)' }}> Do you work with anyone? </h5>
                        <div className="about__about-me__associations flex ">
                            <ReactSVG src={Socan} />
                            <ReactSVG src={Scgc} />
                        </div>
                        



                        <h5 style={{ color: 'var(--main-text)' }}> Why should we work with you? </h5>

                        <p style={{ color: 'var(--main-text)' }}>
                            I am a firm believer that a great soundtrack can make any media spectacular. Together, I want to elevate your project, whether it be a podcast, video game or film, by lending my expertise to help realize your creative vision.


                        </p>


                    </div>


                </div>

            </div>

        </div>
    );
};

export default About;