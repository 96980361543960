import './styles/SectionTitle.css'
import React from 'react';

const SectionTitle = ({ children }) => {
    return (
        <div className="section-title flex flex-row flex-center">
            <div className='section-title__decoration' />
            <h1>{children}</h1>
            <div className='section-title__decoration' />
        </div>
    );
};

export default SectionTitle;