import useScrollPosition from '../../hooks/use-scroll-position/useScrollPosition';
import useIsMobile from '../../hooks/useIsMobile';
import useWindowSize from '../../hooks/useWindowSize';
import Socials from '../socials/Socials';
import './styles/ExternalSocials.css'

import React, { useEffect, useRef, useState } from 'react';

const ExternalSocials = () => {
    const scrollPosition = useScrollPosition()
    const [translate,setTranslate] = useState()
    
    const windowHeight = useWindowSize().height
    const containerRef = useRef()
    const isMobile = useIsMobile()

    const translateHandler = () => {
        const containerHeight = containerRef.current.getBoundingClientRect().height
        const contactHTML = document.getElementsByClassName("contact__content")[0]
        const contactTop =  contactHTML.getBoundingClientRect().top + scrollPosition
        const scrollFromBottom = scrollPosition + windowHeight
        
        if (scrollFromBottom < 100 + windowHeight){
            
            setTranslate(0.5 * windowHeight + containerHeight)
        } else if ((scrollFromBottom >= 100 + windowHeight) && (scrollFromBottom <= contactTop + 0.35 * windowHeight) ){
            setTranslate(0)
            
            
        } else if (scrollFromBottom > contactTop + 0.35 * windowHeight) {
            setTranslate(-0.5 * windowHeight - containerHeight)
        } else {
            console.log('impossible')
        }
    
    }



    

    useEffect(() => {
        translateHandler()
    },[scrollPosition])





    return (
        <div className= "header__external-socials"  style={{zIndex: 999999,display: (isMobile) ? 'none' : 'flex'}} ref={containerRef}>
            <Socials className={` flex-column`} style={{ borderRight: '5px solid var(--main-text)',transition: "transform 0.3s ease-in-out",transform: `translateY(${translate}px)`}}/>
        </div>
    );
};

export default ExternalSocials;