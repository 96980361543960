

import './styles/Header.css';

import React, { useState } from 'react';

import Navigation from './components/navigation/Navigation'
import NavMenu from './components/nav-menu/NavMenu';

import useScrollPosition from '../../hooks/use-scroll-position/useScrollPosition';
const Header = () => {

    const [showNavMenu, setShowNavMenu] = useState(false)
    const scroll = useScrollPosition();
    
    
    const navigateToSection = (section) => {
        const sectionElement = (section) => {
            var res = null;
            if (section === 0) {
                res = document.getElementsByClassName("home__container")[0]
            } else if (section === 1) {
                res = document.getElementsByClassName("listen__container")[0]
            } else if (section === 2) {
                res = document.getElementsByClassName("projects__container")[0]
            } else if (section === 3) {
                res = document.getElementsByClassName("about__container")[0]
            } else if (section === 4) {
                res = document.getElementsByClassName("contact__container")[0]
            } else {
                console.log("Pain and Suffering")
            }
            return res;
        }

        const sectionDistance = sectionElement(section).getBoundingClientRect().top + scroll
        
        window.scrollTo({top: sectionDistance, behavior: 'smooth'})
        
        
    }
    
    return (
        <div className="header__container" style={{
            backgroundColor: "var(--main-" + ((scroll > 0) ? "text" : "background") + ")",
            
            
            }}>
            <div className="header__content flex flex-row flex-align-center">
                <div className='header__title' onClick={() => {navigateToSection(0); setShowNavMenu(false)}}>
                    <h1 className="flex flex-center" style={{color: (showNavMenu || (scroll > 0)) ? "var(--main-background)" : "var(--main-text)"}}> KnightWaters Music </h1>
                </div>
                <Navigation navFn={navigateToSection} showNavMenu={showNavMenu} setShowNavMenu={setShowNavMenu} scrollPosition={scroll}/>
                <NavMenu show={showNavMenu} setShow={setShowNavMenu} navFn={navigateToSection}/>
                
            </div>

        </div>
    );
};

export default Header;