



import './styles/App.css';
import React from 'react'
import Body from './components/body/Body';
import Header from './components/header/Header';
import SmoothScroll from './components/smooth-scroll/SmoothScroll';
import ExternalSocials from './components/external-socials/ExternalSocials';



const App = () => {
  return (
    <div className="app__container">
      <div className='app__content'                                       >

        <Header />
        <ExternalSocials />


        {(matchMedia('(hover: none)').matches && true) ? (

          <Body />


        ) : (
          <SmoothScroll>

            <Body  />

          </SmoothScroll>

        )}



      </div>
    </div>
  );
}

export default App;
