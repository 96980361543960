import './styles/Projects.css'

import React, { useState } from 'react';
import Colourless from '../../../../../resources/images/Movie Posters for Website/Colourless.png'
import Depthpoint from '../../../../../resources/images/Movie Posters for Website/Depthpoint.png'
import Freedom from '../../../../../resources/images/Movie Posters for Website/Freedom.jpg'
import RoadKill from '../../../../../resources/images/Movie Posters for Website/RoadKill.png'
import RoleOfALifetime from '../../../../../resources/images/Movie Posters for Website/RoleOfALifetime.jpg'

import useIsMobile from '../../../../hooks/useIsMobile';
import SectionTitle from '../section-title/SectionTitle';
const Projects = () => {

    const [activeProject, setActiveProject] = useState(null)
    const isMobile = useIsMobile()

    const handleProjectClick = (key) => {
        if (isMobile) {

            if (activeProject === key) {

                setActiveProject(null)

            } else {


                setActiveProject(key)

            }
        }

    }

    const projectData = [
        {
            key: 1,
            name: 'Roadkill',
            type: 'Short Film',
            year: '2019',
            img: RoadKill
        },
        {
            key: 2,
            name: 'Colourless',
            type: 'Video Game',
            year: '2021',
            img: Colourless
        },
        {
            key: 3,
            name: 'Depthpoint',
            type: 'Video Game',
            year: '2021',
            img: Depthpoint
        },
        {
            key: 4,
            name: 'Freedom',
            type: 'Short Film',
            year: '2022',
            img: Freedom
        },
        {
            key: 5,
            name: 'Role of a Lifetime',
            type: 'Short Film',
            year: '2022',
            img: RoleOfALifetime,
        },
    ]



    return (
        <div className="projects__container ">
            <div className="projects__content ">

               <SectionTitle>Projects</SectionTitle>

                <div className='projects__project-view flex flex-center'>
                    {projectData.map((data) => (
                        <div className="grid-item flex flex-center flex-column"key={data.key} style={{ backgroundImage: `url(${data.img})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }} >
                            <div onClick={() => { handleProjectClick(data.key) }}  className='grid-item__overlay flex flex-center flex-column' style={{ opacity: (activeProject === data.key) ? 1 : 0 }} >
                                <p>{data.name}</p>
                                <p>{data.type}</p>
                                <p>{data.year}</p>

                            </div>





                        </div>
                    ))}


                </div>

            </div>

        </div>
    );
};

export default Projects;