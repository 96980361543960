import './styles/Contact.css'

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

import Socials from '../../../socials/Socials';
import SectionTitle from '../section-title/SectionTitle';




const Contact = () => {

    const submitData = {
        default: {
            text: "SEND MESSAGE",
            color: "var(--main-text)",
            width: 'fit-content',
            events: 'all'

        },
        sent: {
            text: "MESSAGE SENT",
            color: "green",
            width: '100%',
            events: 'none'
        }
    }



    const [submitText, setSubmitText] = useState(submitData.default)
    const form = useRef();

    const sendMessageHandler = () => {
        setSubmitText(submitData.sent)
        setTimeout(() => {
            setSubmitText(submitData.default)
        }, 1500)
    }



    const sendEmail = (e) => {
        e.preventDefault();

        sendMessageHandler()

        {/* emailjs.sendForm('service_qf2khx4', 'template_wktuegt', form.current, 'M9kr4DILWJn4kI5Xn')
            .then((result) => {
                
                console.log(result.text);
                console.log("message sent!")
                
            }, (error) => {
                console.log(error.text);
            }); */}


    };

    return (
        <div className='contact__container'>
            <div className='contact__content flex flex-column'>
                <SectionTitle> Contact </SectionTitle>


                <div className='contact__socials-form flex flex-row '>

                    <div className='contact__socials flex  flex-column' style={{ textAlign: 'center', color: 'var(--main-text)' }}>


                        <span  >Reach out on social media </span>
                        <Socials exclusionArr={[5]} />

                    </div>

                    <div className='or-separator flex flex-center flex-column'>
                        <div className="separator first" />
                        <div className="content" style={{ color: 'var(--main-text)', fontWeight: 400, margin: '5px 5px' }}> or </div>
                        <div className="separator last" />
                    </div>

                    <div className="contact__form flex  flex-column" style={{ color: 'var(--main-text)' }}>

                        <span>Send a message directly</span>
                        <form autoComplete="off" ref={form} onSubmit={sendEmail} className="flex flex-column flex-center">


                            <input type="text" name="user_name" placeholder='Name*' required />
                            <input id="email__input" type="email" name="user_email" placeholder='E-mail*' required />

                            <textarea name="message" rows="5" cols="30" placeholder="Message..." required />

                            <div className="contact__form__button flex">
                                <input type="submit" style={{ pointerEvents: submitText.events, width: submitText.width, color: submitText.color, borderColor: submitText.color }} value={submitText.text} />


                            </div>

                        </form>

                    </div>
                </div>
                <div className="flex flex-center" style={{ position: 'relative', bottom: 0, height: '4rem', textAlign: 'center', width: '100%', }}>
                    <span style={{
                        color: 'var(--main-text)',
                        

                    }}>
                        <FontAwesomeIcon icon={faCopyright} /> &nbsp; 2023 KnightWaters Music

                    </span>
                </div>



            </div>


        </div>
    );
};

export default Contact;