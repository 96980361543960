import './styles/NavMenu.css'

import React, { useEffect } from 'react';


const NavMenu = ({ show, setShow, navFn }) => {

    const handleNavOnClick = (section) => {

        navFn(section)



    }

    const NavigationItems = [
        {
            id: 1,
            text: 'LISTEN'
        },
        {
            id: 2,
            text: 'PROJECTS'
        },
        {
            id: 3,
            text: 'ABOUT'
        },
        {
            id: 4,
            text: 'CONTACT'
        },

    ]

    useEffect(() => {
        if (show) {

        } else {

        }
    }, [show])
    return (
        <div className='nav-menu__container' style={{

            opacity: (show) ? 1 : 0,
            pointerEvents: (show) ? 'all' : 'none',

        }} >
            <div className='nav-menu__content flex flex-center'>
                <div className='nav-menu__nav ' >
                    {NavigationItems.map((item) => (
                        <div key={item.id}>
                            <p onClick={() => { handleNavOnClick(item.id); setShow(false) }}> {item.text} </p>
                            <div className="custom-underline" style={{ backgroundColor: 'var(--main-background)' }} />
                        </div>
                    ))}



                    
                </div>
                

            </div>
        </div>
    );
};

export default NavMenu;