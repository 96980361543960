import './styles/Socials.css'
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImdb, faInstagram, faLinkedin, faSoundcloud, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


const Socials = ({ exclusionArr, className, style, color }) => {

    const ImdbSVG = () => {
        return (
            <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" className="imdb-icon">
                <path
                    d="M58.137 28.432h387a22 22 0 0122 22v386a22 22 0 01-22 22h-386a22 22 0 01-22-22v-387a21 21 0 0121-21z"
                    fill="transparent"
                />
                <path
                    d="M117.46 311.072H81.89v-137.4h35.57v137.4zm66.6-73.1l9.1-64.3h46.3v137.4h-31v-92.7l-13.4 92.7h-21.3l-13-90.7-.1 90.7h-31.2v-137.4h46.1c.5 8.3 2.8 18.1 4.3 29.4l4.2 34.9zm67.6 73.1v-137.4h26.6c17 0 27 .9 33 2.4 6.1 1.7 10.7 4.2 13.9 7.9 3.1 3.3 5.1 6.6 5.8 12 .9 4.4 1.4 13.1 1.4 26.2v48.2c0 12.3-.7 20.5-1.9 24.7-1.1 4.1-3.1 7.4-6 9.7-2.8 2.4-6.4 4.1-10.7 5-4.2.8-10.6 1.3-19.1 1.3h-43zm35.5-113.9v89.4c5.1 0 8.3-1 9.4-2.3 1.1-2 1.8-7.6 1.8-16.7v-53.3c0-6.2-.1-10.2-.7-12-.3-1.8-1.2-3-2.6-4.7-1.4 0-4.1-.4-7.9-.4zm57.3 113.9v-137.4h34.1v43.9c2.9-2.4 6.1-4.9 9.5-6.6 3.6-1.5 8.8-2.4 12.8-2.4 4.8 0 8.9.8 12.3 2.2 3.4 1.5 6 3.5 8 6.2 1.7 2.6 2.7 5.3 3.1 7.8.4 2.6-.2 8-.2 16.2v38.6c0 8.2.2 14.3-.8 18.4-1.1 4-3.8 7.6-7.8 9.6-4.1 3.9-8.9 5.3-14.6 5.3-4 0-9.2-.9-12.7-2.5-3.5-1.8-6.7-4.5-9.6-8l-2.1 8.7h-32zm45.1-20.7c.7-1.8 1-6 1-12.5v-35.4c0-5.6-.3-9.5-1.1-11.2-.7-1.9-3.7-2.7-5.8-2.7-2 0-3.4.8-4.1 2.3-.6 1.5-1 5.4-1 11.6v36.4c0 6.1.4 10 1.2 11.6.6 1.7 2.1 2.5 4.1 2.5 2.2 0 4.2-.8 5.7-2.6zm56.8-270.86c15.7 1.23 28.7 15.24 28.7 31.88v384.18c0 16.4-11.9 30.4-28.2 31-.3 0-.5.9-.8.9H57.84c-.28 0-.56-.9-.84-.1-15.73-1.4-27.947-13.8-29.04-30.2l.019-387.92c1.062-15.9 13.801-28.69 30.241-30.68h387.44c.2 0 .5.91.7.94zm-388.13 9.22c-11.27.75-20.25 9.75-21.013 21.14v387.3c.373 5.4 2.653 10.5 6.443 14.3 3.78 3.9 8.81 6.3 14.19 6.7h388.21c11.5-1 20.6-11.6 20.6-22.6V51.382c0-5.74-2.1-11.26-6-15.46-3.9-4.21-9.3-6.78-15-7.19H58.23z"
                    fill="var(--main-text)"
                    paintOrder="fill"
                />
            </svg>
        )
    }
  


    const socialsData = [
        {
            key: 0,
            href: "https://soundcloud.com/knightwatersmusic/",
            icon: faSoundcloud,
            color: '#ff7700'

        },
        {
            key: 1,
            href: "https://www.imdb.com/user/ur166627531/",
            icon: faImdb,
            background: '#F5C518'

        },
        {
            key: 2,
            href: "https://www.linkedin.com/in/josh-aguas-951557187",
            icon: faLinkedin,
            background: '#0077B5'
        },
        {
            key: 3,
            href: "https://www.instagram.com/knightwaters_music/",
            icon: faInstagram,
            background: 'linear-gradient(135deg, rgba(249,206,52,1) 0%, rgba(238,42,123,1) 50%, rgba(98,40,215,1) 100%)'

        },
        {
            key: 4,
            href: "https://twitter.com/knightwatersmus",
            icon: faTwitter,
        },
        {
            key: 5,
            href: "mailto:bassknight4114@gmail.com",
            icon: faEnvelope,
        },
    ]

    const wantedSocials = (exclusionArr) => {
        var res = [];
        for (var i = 0; i < socialsData.length; i++) {
            if (!exclusionArr.includes(socialsData[i].key)) {

                res.push(socialsData[i])
            }
        }
        return res
    }

   



    useEffect(() => {

    }, [])

    return (
        <div className={'socials__content flex flex-center ' + (className || "")} style={style}>
            {wantedSocials((exclusionArr || [])).map((socials) => (
                <a target='_blank' className='flex flex-center' rel="noreferrer" href={socials.href} key={socials.key}  >
                    {((socials.key === 1)) ?
                        <ImdbSVG/>
                        :
                        <FontAwesomeIcon icon={socials.icon}></FontAwesomeIcon>
                    }

                </a>
            ))}
            
        </div>

    );
};

export default Socials;