import { useState, useEffect } from "react";

export default function useIsMobile() {
  const getIsMobile = () => {
    return matchMedia('(hover: none)').matches
  };

  const [isMobile,setIsMobile] = useState(getIsMobile)

  useEffect(() => {
    setIsMobile(getIsMobile())
  }, []);

  return isMobile;
}