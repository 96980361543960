import './styles/Soundcloud.css'

import React, { useState, useEffect, createRef } from 'react';
import loadscript from 'load-script'


const SOUNDCLOUD_URL = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1645193587&color=%23d7c49e&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'

const Soundcloud = ({ demoPlaying, soundcloudPlaying, setSoundcloudPlaying}) => {

    const [player, setPlayer] = useState(false)

    const iframeRef = createRef()
    useEffect(() => {

        // use load-script module to load SC Widget API
        loadscript('https://w.soundcloud.com/player/api.js', () => {

            // initialize player and store reference in state

            const player = window.SC.Widget("soundcloud-widget")
            setPlayer(player)

            

            const { PLAY, PLAY_PROGRESS, PAUSE, FINISH, ERROR } = window.SC.Widget.Events
            

            player.bind( PLAY, () => {
                // update state to playing
                setSoundcloudPlaying(true)
                

                
            })

            player.bind( PAUSE, () => {
                // update state to playing
                setSoundcloudPlaying(false)
                
                
                
            })






        })

    }, [])

    useEffect(() => {
       if (demoPlaying && soundcloudPlaying) {
            player.pause()
       } 
    },[demoPlaying])

   


    return (
        <>

            <iframe className='soundcloud-widget' title='soundcloud-widget' id="soundcloud-widget" ref={iframeRef} style={{ scrolling: "no", frameborder: "no" }} src={SOUNDCLOUD_URL} />
            
        </>
    );
};

export default Soundcloud;
