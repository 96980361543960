import About from './components/about/About';
import Contact from './components/contact/Contact';
import Home from './components/home/Home';
import Listen from './components/listen/Listen';
import Projects from './components/projects/Projects';
import './styles/Body.css'

import React, { useState, useEffect } from 'react';

const Body = ({scrollPosition}) => {

    const [demoPlaying, setDemoPlaying] = useState(false)
    const [soundcloudPlaying, setSoundcloudPlaying] = useState(false)


    
        
    useEffect(() => {
        if (soundcloudPlaying && demoPlaying) {
            setDemoPlaying(false)
        }
        
    }, [soundcloudPlaying])

    return (
        <div className='body__container'>
            <div className='body__content'>
                <Home soundcloudPlaying={soundcloudPlaying} demoPlaying={demoPlaying} setDemoPlaying={setDemoPlaying}/>
                <Listen scroll={scrollPosition} demoPlaying={soundcloudPlaying} soundcloudPlaying={demoPlaying} setSoundcloudPlaying={setSoundcloudPlaying}/>
                <Projects scroll={scrollPosition}/>
                <About scroll={scrollPosition}/>
                <Contact scroll={scrollPosition}/>
            </div>
        </div>
    );
};

export default Body; 